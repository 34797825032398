/* body {
  overflow: hidden;
}

.h5p-brainy-book {
  height: 100vh;
} */

.rotate-device {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h5p-brainy-book .arrow-left {
  position: absolute;
  width: 75px;
  height: 50px;
  bottom: 5px;
  z-index: 2000;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-image: url('/interface/hand_left.png');
  left: 0;
  cursor: pointer;
}

.h5p-brainy-book .arrow-right {
  position: absolute;
  width: 75px;
  height: 50px;
  bottom: 5px;
  z-index: 2000;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-image: url('/interface/hand_right.png');
  right: 0;
  cursor: pointer;
}

.h5p-brainy-book .rec-dot {
  margin: 0px 10px 10px 10px !important;
}

.h5p-brainy-book .rec-dot_active {
  background-color: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.5) !important;
  margin: 0px 10px 10px 10px !important;
}

.h5p-brainy-book .rec-slider-container {
  margin: 0px !important;
}

.h5p-brainy-book .multi-slides {
  width: 100%;
  margin-bottom: 10vh;
}

.h5p-brainy-book-page-icon {
  max-width:100%;
  max-height:100%
}

.h5p-brainy-book .landscape-grid {
  width: 100%;
  height: 90vh;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: 50% 50%;
  justify-content: space-between;
  align-content: stretch;
}

.h5p-brainy-book .landscape-item {
  text-align: center;
  object-fit: contain;
}

.h5p-brainy-book .portrait-grid {
  width: 100%;
  height: 90vh;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 30% 30% 30%;
  justify-content: space-between;
  align-content: stretch;
  grid-auto-flow: column;
}

.h5p-brainy-book .portrait-item {
  text-align: center;
  object-fit: contain;
}
