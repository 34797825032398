#h5p-brainy-lesson {
  position: relative;
  z-index: 2;
}

.h5p-brainy-lesson .title {
  cursor: pointer;
  text-align: center;
  margin-bottom: 0.3em;
}
s
.h5p-brainy-lesson .title-img {
  height: 6rem;
  object-fit: scale-down;
}

.h5p-brainy-lesson .title-txt {
  font-family: Arial, Helvetica, sans-serif;
  background-color: yellow;
  font-weight: bold;
  font-size: 2.5vw;
  color: blue;
  padding: 0.5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.h5p-brainy-lesson .title-sp {
  height: 3vw;
  width: 3vw;
  margin: 0 1vw;
}

.h5p-brainy-lesson .img {
  object-fit: scale-down;
}

.h5p-brainy-lesson .label {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3.5vw;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  padding: 2.5vh;
  background-color: rgba(255, 255, 255);
  border-radius: 15px;
  border: 2px solid black;
}

.h5p-brainy-lesson .landscape-grid {
  width: 100%;
  /* height: 90vh; */
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  margin-top: 3.5vh;
}

.h5p-brainy-lesson .landscape-row {
  display: flex;
  direction: rtl;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  height: 50%;
}

.h5p-brainy-lesson .landscape-item {
  cursor: pointer;
  text-align: center;
}

.h5p-brainy-lesson .portait-grid {
  width: 100%;
  /* height: 100vh; */
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  margin-top: 4vh;
}

.h5p-brainy-lesson .portait-row {
  display: flex;
  direction: rtl;
  flex-wrap: nowrap;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  height: 100%;
}

.h5p-brainy-lesson .portait-item {
  cursor: pointer;
  text-align: center;
}