.h5p-brainy-match .title {
  cursor: pointer;
  text-align: center;
}

.h5p-brainy-match .title-img {
  height: 6rem;
  object-fit: scale-down;
}

.h5p-brainy-match .title-txt {
  font-family: Arial, Helvetica, sans-serif;
  background-color: yellow;
  font-weight: bold;
  font-size: 2.5vw;
  color: blue;
  padding: 0.5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.h5p-brainy-match .title-sp {
  height: 3vw;
  width: 3vw;
  margin: 0 1vw;
}

/***********************
 * RELATED TO COLUMNS MODE
 ***********************/

 .h5p-brainy-match .columns {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  direction: rtl;
  /* height: 90vh; */
  margin-top: 4vh;
}

.h5p-brainy-match .column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
}

.h5p-brainy-match .item-column {
  background-color: #00aeef;
  border-radius: 20px;
  padding: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 15vw;
  touch-action: manipulation;
  position: relative;

  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 4vw;
  line-height: 6vw;
  color: white;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.h5p-brainy-match .item-img {
  height: 95%;
  width: unset;
  object-fit: contain;
  padding: 0.5rem;
  pointer-events: none;
}

.h5p-brainy-match .answer-sign-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  left: 0.2vh;
}

.h5p-brainy-match .answer-sign-container .answer-sign {
  width: 90%;
  height: 90%;
  opacity: 0.7;
  object-fit: contain;
  vertical-align: unset;
}

/***********************
 * RELATED TO ROWS MODE
 ***********************/

 .h5p-brainy-match .rows {
  display: flex;
  /* height: 80vh; */
  margin-top: 4vh;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  direction: rtl;
}

.h5p-brainy-match .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 50%;
}

.h5p-brainy-match .item-row {
  width: 30%;
  height: 70%;
  background-color: #00aeef;
  border-radius: 20px;
  padding: 1rem;
  /* margin: 0.5rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  touch-action: manipulation;
  position: relative;

  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 4vw;
  line-height: 6vw;
  color: white;
  text-align: center;
  cursor: pointer;
}

.item-ltr {
  font-size: 3vw !important;
}

.h5p-brainy-match .item-row img {
  height: 95%;
  width: unset;
  object-fit: contain;
  padding: 0.5rem;
  pointer-events: none;
}

.droptarget {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.drag-item-sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
}