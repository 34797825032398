body {
  overflow: hidden;
}

/* .h5p-brainy-multi {
  height: 95vh;
} */

.arrow-left {
  position: absolute;
  width: 75px;
  height: 50px;
  bottom: 5px;
  z-index: 2000;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-image: url('/interface/hand_left.png');
  left: 0;
  cursor: pointer;
}

.arrow-right {
  position: absolute;
  width: 75px;
  height: 50px;
  bottom: 5px;
  z-index: 2000;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-image: url('/interface/hand_right.png');
  right: 0;
  cursor: pointer;
}

/* @media all and (orientation: landscape) {
    width: 15vw;
  height: 10vh;
  .arrow-left {
    width: 10vw;
  }
  .arrow-right {
    width: 10vw;
  }
} */

.rotate-device {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page {
  position: absolute;
  left: 0.5em;
  top: 0.3em;
  cursor: pointer;
  z-index: 100;
}

/* .home-page img {
  height: 7vh;
  object-fit: scale-down;
} */

.home-back {
  background: rgba(255, 255, 255, 0.35);
}

.rec-dot {
  margin: 0px 10px 10px 10px !important;
}

.rec-dot_active {
  background-color: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.5) !important;
  margin: 0px 10px 10px 10px !important;
}

.rec-slider-container {
  margin: 0px !important;
}

.multi-slides {
  width: 100%;
  margin-bottom: 10vh;
}
